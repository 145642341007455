import { Seo } from './../_models/seo';
import { TranslationsService } from './_services/translations/translations.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {BehaviorSubject, filter, map, mergeMap} from 'rxjs';
import { SeoService } from './_services/seo/seo.service';
import {isPlatformBrowser} from "@angular/common";
import { AppChatService } from './_services/app/app-chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Drzew Wiśni';
  static isBrowser = new BehaviorSubject<boolean>(true);

  constructor(
    private router: Router,
    private translationsService: TranslationsService,
    private changeRef: ChangeDetectorRef,
    private seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    private appChat: AppChatService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
  }

  ngOnInit(): void {
    this.translationsService.getTranslations().subscribe((translations) => {
      loadTranslations(translations);
      this.changeRef.markForCheck();
    });

    this.router.events
      .pipe(
        filter((val): val is NavigationEnd => val instanceof NavigationEnd),
        map(() => this.rootRoute(this.activatedRoute)),
        filter((route: ActivatedRoute) => {
          return route.outlet === 'primary';
        }),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { seo?: Seo }) => {
        this.appChat.resetTime();
        this.seoService.updateSeo(event.seo);
      });




  }

  ngAfterViewInit() {
   this.appChat.init();

    document.addEventListener('click', () => {
      this.appChat.resetTime();
    });

    document.addEventListener('scroll', () => {
      this.appChat.resetTime();
    });
  }


  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
