import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AppChatService {
  private _time: number = 10000;
  private _multipleOpen = false;
  private _buttonClass = '.ds-widget-chat-button';
  private _isExecuted: boolean = false;
  private _buttons: NodeListOf<Element> | undefined;
  private _resetSubject = new Subject<void>();

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    if (isPlatformBrowser(this.platformId)) {
      this._resetSubject.pipe(
        debounceTime(this._time)
      ).subscribe(() => {
        this.executeClick();
      });
    }
  }

  public init() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this._buttons) {
        this._buttons = document.querySelectorAll(this._buttonClass);
      }

      this._resetSubject.next();
    }
  }

  private executeClick() {
    if (isPlatformBrowser(this.platformId) && (!this._buttons || (this._isExecuted && !this._multipleOpen))) {
      return;
    }

    this._buttons?.forEach((button: any) => {
      button.click();
    });

    this._isExecuted = true;
  }

  public resetTime() {
    if (isPlatformBrowser(this.platformId)) {
      this._resetSubject.next();
    }
  }
}
